import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { isSpecialText, useCanvas, useCanvasDispatch } from '@/contexts/editor/CanvasContext';
import Konva from 'konva';
import TextAlignmentSelector from '@/components/editor/sidebar/TextAlignmentSelector';
import { DynamicTextTypes, PriceType } from '@/types/canvas';
import DynamicTextOptions, { getTextForType } from '@/components/editor/buttons/DynamicTextOptions';
import FontFamilyOptions, { FamilyOptions } from '@/components/editor/buttons/FontFamilyOptions';
import TextHeader from './partials/TextHeader';
import { useMember } from '@/contexts/member/MemberContext';
import FixedTextOptions from '../buttons/FixedTextOptions';
import { Slider } from '@mui/material';
import ProductAttributionOptions from '../buttons/ProductAttributionOptions';
import ProductField from '@/components/buttons/ProductFields';
import { ProductListItem } from '@/types/products';
import Notify from '@/helpers/Notify';

type TextContainerProps = {};
type PriceTypeOption = { id: PriceType, label: string };

const TextContainer = ({ }: TextContainerProps) => {
	const { t: c } = useTranslation('canvas.translation');

	const [align, setAlignment] = useState('left');
	const [innerText, setInnerText] = useState('');
	const [fontFamily, setFontFamily] = useState('');
	const [selectedPriceType, setSelectedPriceType] = useState<PriceType | null>(null);
	const [textWaitingForResize, setWaitingForResize] = useState<string | null>(null);
	const [disableSlider, setDisableSlider] = useState<boolean>(false);

	const { member } = useMember();

	const {
		state,
		shapeInfo,
		isEditing,
		getElementDataById,
		updateFontSize,
		writeOnSelected,
		updatePriceType,
		currentFontSize,
		setFontSize,
		setTemplateType,
	} = useCanvas();

	const { stateDispatch } = useCanvasDispatch();

	const { elements, current, products } = state;

	const currentElement = getElementDataById(current.id);
	const isPriceElement = currentElement?.attributes.priceType
		|| currentElement?.attributes.textType === "price_per_gram";

	useEffect(() => {
		setTemplateType();
	}, [selectedPriceType]);

	useEffect(() => {
		if (currentElement && (textWaitingForResize === currentElement.attributes.id)) {
			if (currentElement.ref && currentElement.ref.current) {
				const ref = currentElement.ref.current;
				// @ts-ignore
				ref.autosize();
				setWaitingForResize(null);
			}
		}
	}, [elements]);

	useEffect(() => {
		if (current.id && currentElement && currentElement.ref?.current && shapeInfo.isText) {
			const ref = currentElement.ref.current as Konva.Text;
			setFontSize(ref.fontSize());
			setDisableSlider(false);
		} else {
			setDisableSlider(true)
		}
	}, [current])

	useEffect(() => {
		if (current.id && currentElement && currentElement.ref?.current && shapeInfo.isText) {
			const ref = currentElement.ref.current as Konva.Text;

			const currentAlign = ref.align();
			const currentText = ref.text();
			const currentFontFamily = ref.fontFamily();
			const currentPriceType = currentElement?.attributes.priceType || null;

			if (currentAlign !== align) setAlignment(currentAlign);
			if (currentText !== innerText) setInnerText(currentText);
			if (currentFontFamily !== fontFamily) setFontFamily(currentFontFamily);
			if (currentPriceType !== selectedPriceType) setSelectedPriceType(currentPriceType);
		}
	}, [state]);

	const getSelectedElementText = () => {
		const textElementExists = (shapeInfo.isText && current.id && currentElement && currentElement.ref?.current);
		if (textElementExists) {
			// @ts-ignore
			const ref = currentElement.ref.current as Konva.Text;
			return currentElement.attributes.defaultText || ref.text();
		}
		return '';
	}

	const getPriceTypes = (): PriceTypeOption[] => {
		return [
			{ id: 'simple', label: 'Comum / Regular' },
			{ id: 'offer', label: 'Oferta' },
			{ id: 'club', label: 'Clube' },
			{ id: 'extra', label: 'Extra' },
		]
	}

	const handleUpdatePriceType = (type: PriceType | null) => {
		setSelectedPriceType(type);
		updatePriceType(type);
	}

	const updateDynamicText = async (option: DynamicTextTypes | null) => {
		if (currentElement?.ref && shapeInfo.isText) {
			const ref = currentElement.ref.current;
			const text = getTextForType(option) || currentElement.attributes.text || '';
			// @ts-ignore
			ref.writeText(text);
		}
	}

	const applyDefaultText = () => {
		elements.forEach((element) => {
			if (isSpecialText(element.attributes.textType) && element.attributes.defaultText) {
				const attributes = {
					...element.attributes,
					text: element.attributes.defaultText
				}
				stateDispatch({
					type: "updateElement",
					payload: {
						id: element.attributes.id,
						attributes
					}
				});
			}
			else if (element.attributes.textType) {
				let text = '';
				switch (element.attributes.textType) {
					case 'description':
						text = 'DESCRIÇÃO DO PRODUTO';
						break;
					case 'price':
						text = '0,00';
						break;
					case 'digits':
						text = '00';
						break;
					case 'cents':
						text = ',00';
						break;
				}
				if (text) {
					stateDispatch({
						type: "updateElement", payload: {
							id: element.attributes.id,
							attributes: { ...element.attributes, text }
						}
					});

				}
			}
		})
	}

	const toggleUppercaseCheckbox = () => {
		const useUpperCase = !currentElement?.attributes.useUpperCase;

		if (currentElement) {
			const attr = currentElement.attributes;
			stateDispatch({
				type: "updateElement",
				payload: {
					id: attr.id,
					attributes: {
						...attr,
						text: useUpperCase ? attr.text : (attr.text?.toLowerCase() || attr.text),
						useUpperCase
					}
				}
			})
		}
	}

	const getProductsLabelList = () => {
		return products.ids.map((_, index) => `Produto ${index + 1}`);
	}


	const TEXT_MAP = {
		ean: 'EAN',
		innerCode: 'Código Interno',
		description: 'Descrição Do Produto',
		packType: 'UN',
		packQuantity: '1',
		sPrice: '0,00',
		cPrice: '0,00',
		oPrice: '0,00',
		ePrice: '0,00',
	}

	const getProductLabelByID = (id: string | null): string | null => {
		if (!id) return null;
		const index = products.ids.indexOf(id);
		return getProductsLabelList()[index] ?? null;
	}

	const updateTextInfo = (key: keyof ProductListItem | null) => {
		if (!currentElement) return;

		let text = currentElement.attributes.text; // default text

		if (text === 'Novo Texto') {
			if (key && TEXT_MAP.hasOwnProperty(key)) {
				text = TEXT_MAP[key as keyof typeof TEXT_MAP];
			}
			else if (key && /^extraParameter_\d+$/.test(key)) {
				const extraParamNumber = key.split('_')[1];
				text = `Extra ${extraParamNumber}`;
			}
		}

		stateDispatch({
			type: "updateElement",
			payload: {
				id: current.id as string,
				attributes: {
					...currentElement.attributes,
					source: key,
					textType: 'product_source',
					defaultText: text,
					text
				}
			}
		});
	}


	const doCreateProduct = () => {
		stateDispatch({ type: "createProduct" });
		Notify.success("Novo produto disponível para edição").bottomLeft();
	}

	// solucao para que o input nao fique carregando o value a cada alteracao.
	const [textoInput, setTextInput] = useState('');

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newText = event.target.value;
		setTextInput(newText);
		writeOnSelected(newText);
	};

	const currentText = getSelectedElementText();

	return (
		<Fragment>
			<TextHeader />
			<div className="flex flex-col space-y-4 p-4 xl:px-10">
				<div className='flex flex-col w-full text-zinc-600'>
					<label className='font-semibold text-zinc-700'>Conteúdo do Texto:</label>
					<div className='flex items-center justify-center gap-2'>
						<input
							disabled={!shapeInfo.isText}
							placeholder={c("editor.text.insertText") as string}
							value={textoInput !== '' ? textoInput : currentText}
							onChange={handleInputChange}
							className="w-full p-2 border border-zinc-300 rounded-md"
						/>
						<FixedTextOptions disable={!shapeInfo.isText} />
					</div>
				</div>

				{
					isEditing && (
						<>
							<div className='flex items-center justify-between text-zinc-600 mb-4'>
								<div className="w-full z-10">
									<p className='text-zinc-600 font-semibold'>Fonte:</p>
									<FontFamilyOptions
										defaultValue={fontFamily as FamilyOptions}
										disabled={!shapeInfo.isText}
									/>
								</div>
							</div>

							<div className='flex items-center justify-between text-zinc-600'>
								<div className='w-full mr-4 gap-1 flex-grow flex items-center justify-center'>
									<label className='font-bold'>T-</label>
									<Slider
										sx={{
											color: "rgb(59 130 246)",
											'& .MuiSlider-thumb': {
												height: 20,
												width: 20,
												backgroundColor: '#fff',
												border: '1px solid currentColor',
												'&:hover': {
													boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
													zIndex: 10,
												},
												'& .airbnb-bar': {
													height: 9,
													width: 1,
													backgroundColor: 'currentColor',
													marginLeft: 1,
													marginRight: 1,
												},
											},
											'& .MuiSlider-track': {
												height: 3,
											},
											'& .MuiSlider-valueLabel': {
												lineHeight: 1.2,
												fontSize: 12,
												background: 'unset',
												padding: 0,
												width: 32,
												height: 32,
												borderRadius: '50% 50% 50% 0',
												backgroundColor: 'rgb(59 130 246)',
												transformOrigin: 'bottom left',
												transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
												'&:before': { display: 'none' },
												'&.MuiSlider-valueLabelOpen': {
													transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
												},
												'& > *': {
													transform: 'rotate(45deg)',
												},
											},
										}}
										disabled={disableSlider || !isEditing}
										marks={false}
										max={300}
										min={10}
										step={5}
										value={currentFontSize}
										size="medium"
										valueLabelDisplay="auto"
										onChange={(e, val) => updateFontSize(val as number)}
										aria-labelledby="input-slider"
									/>
									<label className='font-bold'>T+</label>
								</div>
								<div className='w-fit flex justify-center items-center gap-4'>
									<TextAlignmentSelector isText={shapeInfo.isText} align={align} />

									<div className={`flex items-center justify-center h-10 w-10 font-bold rounded-md cursor-pointer
										border border-white hover:border-zinc-200
										${shapeInfo.isText
										&& currentElement?.attributes.useUpperCase && "bg-blue-500 text-white"}
									${!shapeInfo.isText && "cursor-not-allowed bg-white text-zinc-300"}`}
										onClick={toggleUppercaseCheckbox}
									>
										Aa
									</div>
								</div>
							</div>

							<Divider />
						</>
					)
				}

				{
					member.can('create_banner') && isEditing && (
						<>
							<div className='flex items-start justify-center text-zinc-600 border-zinc-300'>
								<div className="w-full flex z-0 flex-col items-start justify-start">
									<label className='w-full text-zinc-600 font-semibold'>Tipo de Texto:</label>
									<DynamicTextOptions
										targetID={currentElement?.attributes.id || null}
										defaultValue={currentElement?.attributes.textType as DynamicTextTypes || null}
										onChange={(e) => updateDynamicText(e)}
										disabled={!shapeInfo.isText}
									/>
								</div>

							</div>
						</>
					)
				}

				{
					isPriceElement && (
						<>
							<div className='flex flex-col items-start justify-center'>
								<label className='w-full text-zinc-600 font-semibold'>Tipo de Preço:</label>
								<select
									value={selectedPriceType || ''}
									onChange={(event) => handleUpdatePriceType(event.target.value as PriceType)}
									className="w-full text-zinc-500 appearance-none focus:outline-none border p-2 px-3 rounded-md border-zinc-300"
								>
									{
										getPriceTypes().map(priceType =>
											<option key={priceType.id} value={priceType.id}>{priceType.label}</option>
										)
									}
								</select>
							</div>
						</>
					)
				}

				{
					member.can('create_banner') && isEditing && (
						<>
							<div className='w-full flex flex-col pb-2'>
								<div className='flex items-center justify-between'>
									<div className='flex flex-col'>
										<label className='w-fit font-semibold text-zinc-700'>Retornar Ao Texto Padrão:</label>
										<small className='text-xs text-zinc-600'>Isso será aplicado à todos os elementos to cartaz.</small>
									</div>
									<button
										className='bg-orange-500 hover:bg-orange-600 cursor-pointer text-white font-bold py-2 px-4 rounded-md'
										onClick={applyDefaultText}
									>
										Restaurar
									</button>
								</div>
							</div>
						</>
					)
				}

				<Divider />

				<div className='h-full flex flex-col rounded-md'>
					<div className='w-full text-center pb-4'>
						<span className='text-xl font-semibold text-zinc-600'>Relacionar Texto</span>
						<p className='text-sm text-zinc-500'>
							Para vincular o campo de um produto a um elemento do cartaz, selecione o produto desejado e escolha os campos que deseja usar.
						</p>
					</div>
					<div className='flex flex-col'>
						<div className='flex flex-col items-center justify-between'>
							<label className='w-full text-zinc-600 font-semibold'>Produto:</label>
							<div className="flex w-full space-x-1">
								<ProductAttributionOptions
									defaultValue={(currentElement && current.info.isText) ? getProductLabelByID(currentElement.attributes.productID) : null}
									disabled={!shapeInfo.isText}
								/>
								<button onClick={doCreateProduct} className={`${shapeInfo.isText ? 'bg-orange-500 hover:bg-orange-600 cursor-pointer' : 'bg-gray-400 cursor-not-allowed'} p-2 rounded-md text-white w-12`}>+</button>
							</div>
						</div>
						{products.ids.length === 0 && <small className='w-full text-center mt-2'>Crie ao menos um produto para continuar.</small>}
					</div>

					{
						currentElement?.attributes.productID && (
							<div className='flex flex-col items-center justify-between mt-2'>
								<label className='w-full text-zinc-600 font-semibold'>Parâmetro do Produto:</label>
								<div className="w-full">
									<ProductField
										onChange={updateTextInfo}
										defaultValue={currentElement.attributes.source}
									/>
								</div>
							</div>
						)
					}
				</div>
			</div>
		</Fragment >
	);
}

export default TextContainer;

const Divider: React.FC = () => (<div className='w-full border-b-2 border-gray-300 ' />)
