import React, { useEffect, useState } from "react";
import { FieldOption, OptionGroup, __FIELD_OPTIONS__ } from "../editor/buttons/DynamicTextOptions";
import ArrowDownIcon from "../svg/ArrowDownIcon";
import ArrowRightIcon from "../svg/ArrowRightIcon";
import { useCanvas } from "@/contexts/editor/CanvasContext";

interface IDropdownMenu {
    disable: boolean;
    onChange: (option: { value: string, label: React.ReactNode }) => void;
    defaultOption: FieldOption | undefined;
}

const DropDownMenu: React.FC<IDropdownMenu> = ({ disable, onChange, defaultOption }) => {
    const [openMenuIndex, setOpenMenuIndex] = useState<number | null>(null);
    const [select, setSelected] = useState<FieldOption>();
    const [modalIsOpen, setModalOpen] = useState(false);

    const { selectProduct } = useCanvas();

    useEffect(() => {
        setSelected(defaultOption);
        setOpenMenuIndex(null);
        setModalOpen(false);
        setModalOpen(false);
    }, [defaultOption, selectProduct])

    const handleToggleMenu = (index: number) => {
        setOpenMenuIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const DropdownGroup: React.FC<{ group: OptionGroup; isOpen: boolean; onToggle: () => void }> = ({ group, isOpen, onToggle }) => {
        return (
            <ul>
                <button
                    onClick={onToggle}
                    className={`flex items-center justify-between w-full px-4 py-2 mr-2 font-semibold ${isOpen ? "bg-zinc-100" : ""} hover:bg-gray-100`}
                >
                    {group.name}
                    <ArrowRightIcon className={`text-zinc-400 ${isOpen ? "rotate-180" : ""} `} />
                </button>

                {isOpen && (
                    <li className="absolute top-0 left-full bg-white divide-y divide-zinc-200 border border-zinc-200 rounded-lg shadow w-48 dark:bg-gray-700">
                        {group.items.map((item, index) => (
                            <DropdownItem key={index} item={item} />
                        ))}
                    </li>
                )}
            </ul>
        );
    };

    const DropdownItem: React.FC<{ item: FieldOption }> = ({ item }) => {
        return (
            <li>
                <a onClick={() => { setSelected(item), setOpenMenuIndex(null), setModalOpen(false), onChange && onChange(item) }} href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                    {item.label}
                </a>
            </li>
        );
    };

    return (
        <div className="relative w-full">
            <button
                id="multiLevelDropdownButton"
                className={`w-full h-10 flex items-center justify-between p-2 px-4 border border-zinc-300 rounded-md ${disable ? "bg-zinc-100 text-zinc-400" : ""}`}
                onClick={() => setModalOpen(!modalIsOpen)}
                disabled={disable}
            >
                <p className="flex items-start w-48 truncate">
                    {select ? select?.label : "Selecione um Campo"}
                </p>

                <ArrowDownIcon className={`text-zinc-300 w-3 h-3 ms-3 ${modalIsOpen ? "rotate-180" : ""}`} />
            </button>

            <div className={`absolute mt-1 border border-zinc-200 bg-white ${modalIsOpen ? "block" : "hidden"} rounded-lg shadow w-fit text-sm text-zinc-700`}>
                <ul className="max-h-52 overflow-y-auto divide-y divide-gray-200 rounded-lg">
                    {__FIELD_OPTIONS__.map((group, index) => (
                        <DropdownGroup
                            key={index}
                            group={group as OptionGroup}
                            isOpen={openMenuIndex === index}
                            onToggle={() => handleToggleMenu(index)}
                        />
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default DropDownMenu;
